<template>
  <div>
    <TheIndexHeader/>
    <router-view v-if="isFullPageLoading==false"></router-view>
    <!-- 浮動按鈕 -->
    <!-- <div class="quickly-funcs">
      <!- - 下載按鈕 - ->
      <el-button
        type="primary"
        class="quickly-btn"
        plain
        v-if="isShowDownloadBtn"
        @click="handleDownloadBtnClick">
        <span v-if="isDownloading">
          {{String(downloadingPercent)}}%
        </span>
        <font-awesome-icon
          v-else
          :icon="[ 'fas', 'download' ]"/>
      </el-button>
      <!- - 問題回報 - ->
      <el-button
        type="primary"
        class="quickly-btn"
        plain
        @click="handleReportBtnClick">
        <font-awesome-icon :icon="[ 'fas', 'question' ]" />
      </el-button>
    </div> -->
    <!-- 問題回報lightbox -->
    <!-- <ReportLightbox
      :visible.sync="isReportLightboxVisible">
    </ReportLightbox> -->
  </div>
</template>

<script>
import store from '@/store/index.js'
import { mapState, mapActions, mapMutations } from 'vuex'
import * as apis from '@/apis/index.ts'
import TheIndexHeader from '@/components/TheIndexHeader/TheIndexHeader.vue'
// import ReportLightbox from '@/components/ReportLightbox/ReportLightbox.vue'

export default {
  components: {
    TheIndexHeader,
    // ReportLightbox
  },
  data () {
    return {
      // isReportLightboxVisible: false,
      // isDownloading: false,
      // downloadingPercent: 0,
      // isShowDownloadBtn: false,
      // downloadFn: null
    }
  },
  computed: {
    ...mapState([
      'isFullPageLoading'
    ])
  },
  methods: {
    // ...mapMutations([
    //   'mutationIsFullPageLoading'
    // ]),
    ...mapActions([
      'actionInitData'
    ]),
    // handleReportBtnClick () {
    //   this.isReportLightboxVisible = true
    // },
    async handleDownloadBtnClick () {
      // // 開始下載
      // this.isDownloading = true
      // // 後端是否已回傳檔案
      // let isProgressStart = false

      // // -- 數字跑動 --
      // let t = 200 // 0.2s
      // const downloadingNumberRun = () => {
      //   if (isProgressStart === true) {
      //     return
      //   }
      //   const r = Math.floor(Math.random() * 5) + 1 // 1~5
      //   this.downloadingPercent += r
      //   // 數字愈大就愈慢，超過80停下來
      //   if (this.downloadingPercent > 80) {
      //     return
      //   } else if (this.downloadingPercent > 50) {
      //     t += 100
      //   }
      //   setTimeout(() => {
      //     downloadingNumberRun()
      //   }, t)
      // }
      // downloadingNumberRun()

      // -- 查詢 --
      // if (this.$route.query.name === 'companyList') {
      //   let result = await apis.companyListGet(searchRequest)
      //     .then((data) => {
      //       commit('mutationCompanyList', data.payload.list || [])
      //       commit('mutationCompanyCount', data.payload.stat['total-count'])
      //       return true
      //     })
      //     .catch(e => {
      //       return false
      //     })
      // } else if (this.$route.query.name === 'companyDetail') {

      // } else if (this.$route.query.name === 'multiCompanyDetailByCompany') {

      // } else if (this.$route.query.name === 'multiPersonDetailByPerson') {

      // } else {

      // }

      // import('@/assets/Export2Excel.js').then(excel => {
      //   // debugger
      //   // const tHeader = ['申請日', '就診日', '學員姓名', '學員職級', '教師姓名', '教師職級', '科別', '地點', '其他地點', '病歷號碼', '病人狀況及診斷', '病例複雜程度', '學員整體表現', '醫療面談', '身體檢查', '技能操作', '諮商衛教', '臨床判斷', '組織效能', '人道專業', '質性回饋']
      //   const tableData = this.getterTableData
      //   if (!tableData.length) {
      //     return
      //   }
      //   const data = tableData.map(d => {
      //     return this.searchRecordFormat.map(f => d[f.index] || '')
      //   })
      //   const header = this.searchRecordFormat.map(f => f.title)
      //   const dateString = moment().format('YYYY-MM-DD')

      //   excel.export_json_to_excel({
      //     header, //表头 必填
      //     data, //具体数据 必填
      //     filename: `mini-CEX.tw.entrust_[${dateString}]`, //非必填
      //     autoWidth: true, //非必填
      //     bookType: 'xlsx' //非必填
      //   })
      // })

      // -- case 後端直接提供api下載 --
      // // 開始下載
      // const startCallback = () => {
      //   isProgressStart = true
      // }
      // // 下載進行中事件
      // const progressCallback = (event) => {
      //   const currentPercent = parseInt(100 * (event.loaded / event.total))
      //   if (currentPercent > this.downloadingPercent) {
      //     this.downloadingPercent = currentPercent
      //   }
      // }
      // // 下載完成事件
      // const loadedCallback = (event) => {
      //   this.downloadingPercent = 100
      //   setTimeout(() => {
      //     this.isDownloading = false
      //     this.downloadingPercent = 0
      //   }, 3000)
      // }
      // // 下載
      // this.downloadFn(null, startCallback, progressCallback, loadedCallback)
      //   .catch(e => {
      //     this.isDownloading = false
      //     this.downloadingPercent = 0
      //   })
    }
  },
  async beforeRouteEnter (to, from, next) {

    // 初始化資料
    store.dispatch('actionInitData')
    // // 紀錄初始query
    // if (to.query && Object.keys(to.query).length) {
    //   let originQuery = null
    //   if (to.meta && to.meta.queryEncode === true) {
    //     let result = await apis.decode({
    //       key: to.query.query // key為'query'
    //     })
    //     if (result.success == true && result.value) {
    //       originQuery = JSON.parse(result.value)
    //     } else {
    //       // 比對不到查詢key回首頁
    //       next({ name: 'home' })
    //     }
    //   } else {
    //     originQuery = to.query
    //   }
    //   store.commit('mutationOriginRouteQuery', originQuery)
    // }
    next(vm => {
    })
  },
  mounted () {
    // this.mutationIsFullPageLoading(false)
  },
  // watch: {
  //   '$route': {
  //     handler (d) {
  //       if (d.name) {
  //         // -- 設定下載按鈕 --
  //         if (d.name === 'companyList') {
  //           const query = d.query.currentQuery
  //           this.isShowDownloadBtn = true
  //           this.downloadFn = apis.testDownload
  //         } else if (d.name === 'companyDetail') {
  //           const uniid = d.query.uniid
  //           this.isShowDownloadBtn = true
  //           this.downloadFn = apis.testDownload
  //         } else if (d.name === 'multiCompanyDetailByCompany') {
  //           const uniid = d.params.menuIndex
  //           this.isShowDownloadBtn = true
  //           this.downloadFn = apis.testDownload
  //         } else if (d.name === 'multiPersonDetailByPerson') {
  //           const keyword = d.params.menuIndex
  //           this.isShowDownloadBtn = true
  //           this.downloadFn = apis.testDownload
  //         } else {
  //           this.isShowDownloadBtn = false
  //           this.downloadFn = null
  //         }
  //       }
  //     },
  //     immediate: true
  //   }
  // },
  errorCaptured(err, vm, info) {
    // err: error trace
    // vm: component in which error occured
    // info: Vue specific error information such as lifecycle hooks, events etc.
    // TODO: Perform any custom logic or log to server
    // return false to stop the propagation of errors further to parent or global error handler
    // console.log('--index.vue errorCaptured--')
    // console.log('err:')
    // console.log(err)
    // console.log('vm:')
    // console.log(vm)
    // console.log('info:')
    // console.log(info)
  }
}
</script>
