<template>
  <header>
    <div class="header-left">
      <router-link :to="{ path: '/' }">
        <img class="logo" style="width: 180px;" src="@/assets/images/logo.svg" alt="logo">
      </router-link>
    </div>
    <div class="header-right">
      <div class="header-input">
        <!-- <el-input
          placeholder="搜尋關鍵字..."
          prefix-icon="el-icon-search"
          v-model="searchInput"
          @keyup.enter.native="search">
        </el-input> -->
        <el-autocomplete
          placeholder="搜尋關鍵字..."
          prefix-icon="el-icon-search"
          :trigger-on-focus="false"
          :fetch-suggestions="autocomplete"
          :debounce="300"
          v-model="searchInput"
          @select="handleSelect"
          @keyup.enter.native="search">
        </el-autocomplete>
      </div>
      <el-dropdown class="header-user" trigger="click" @command="handleDropdownClick">
        <span class="el-dropdown-link">
          <font-awesome-icon :icon="[ 'fas', 'user' ]" />{{userName}}<i class="el-icon-arrow-down el-icon--right"></i>
        </span>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item><router-link :to="{ name: 'collections' }">我的收藏</router-link></el-dropdown-item>
          <el-dropdown-item><router-link :to="manageLinkTo">管理</router-link></el-dropdown-item>
          <el-dropdown-item command="logout">登出</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
  </header>
</template>

<script>
import * as codes from '@/const/codes.ts'
import * as apis from '@/apis/index.ts'
import { mapGetters, mapState, mapMutations, mapActions } from 'vuex'

export default {
  name: 'header',
  data () {
    return {
      searchInput: ''
    }
  },
  computed: {
    ...mapGetters([
      'getterIsManager',
      'getterUserEmail'
    ]),
    ...mapState([
      'userInfo'
    ]),
    manageLinkTo () {
      if (this.getterIsManager) {
        return { name: 'manageAdmin' }
      } else {
        return { name: 'managePersonal' }
      }
    },
    userName () {
      return (this.userInfo && this.userInfo.name) ? this.userInfo.name : ''
    }
  },
  methods: {
    ...mapMutations([
      'mutationIsFullPageLoading'
    ]),
    ...mapActions([
      'actionLogout'
    ]),
    async handleDropdownClick (command) {
      if (command === 'logout') {
        this.mutationIsFullPageLoading(true)
        await this.actionLogout()
        window.location.href = '/'
      }
    },
    search () {
      // 查詢條件
      let query = {
        basic: {
          get: ['list', 'cls'],
          'page-index': 1,
          sort: JSON.parse(codes.DEFAULT_COMPANY_LIST_SORT_VALUE),
          keyword_type: 'all',
          'search-label': {
            user: this.getterUserEmail,
          }
        }
      }
      if (this.searchInput) {
        query.basic.keyword = this.searchInput
        query.basic['search-label'].label = this.searchInput
      } else {
        query.basic.ignore_keyword = 1
      }
      // query
      const queryString = JSON.stringify(query)
      this.$router.push({
        name: 'companyList',
        params: {
          dataTypeIndex: 'default' // 初始值為預設，進入查詢頁再依查詢結果塞值
        },
        query: {
          originQuery: queryString,
          currentQuery: queryString
        }
      })
    },
    handleSelect () {
      this.search()
    },
    autocomplete (queryString, cb) {
      let searchRequest = {
        'basic':{
          'get':[
            'list'
          ],
          'keyword': queryString,
          'keyword_type': 'all',
          'fetch_less_fields': 1,
          'page-size': 10,
          'page-index': 1,
          'search-label': {
            user: this.getterUserEmail,
            label: queryString
          }
        }
      }
      apis.companyListGet(searchRequest).then((data) => {
        if (data && data.payload && data.payload.list) {
          let list = []
          // 過濾重覆的資料
          list = data.payload.list.reduce((prev, data) => {
            if (prev.find(d => d.value === data.value) == null) {
              prev.push(data)
            }
            return prev
          }, [])
          // -- 顯示格式所需資料 --
          list = data.payload.list.map(d => {
            d.value = d.company_name
            return d
          })
          // -- 回傳值 --
          cb(list)
        }
      })
    },
  },
  watch: {
    '$route': {
      handler (d) {
        // 回首頁時清空輸入框文字
        if (d.name === 'home') {
          this.searchInput = ''
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    position: sticky;
    z-index: 2000; // 為避免被openstreetmap蓋過所以設超大
    top: 0;
    height: 56px;
    padding: 0 24px;
    box-shadow: 0 2px 4px rgba(#000, 0.15);
    background-color: #000;
    border-bottom: 5px solid #c00;
    a {
      display: block;
    }
    .logo {
      object-fit: contain;
    }
    .header-right {
      width: auto;
      .header-input {
        display: inline-block;
        vertical-align: middle;
        margin: 0 0.5rem;
        .el-input__inner {
          background-color: #F5F7FA;
          border: 0;
        }
      }
      .header-user {
        margin: 0 1rem;
        vertical-align: middle;
        cursor: pointer;
        .el-dropdown-link {
          color: #FFF;
        }
        .svg-inline--fa {
          width: 12px;
          height: 12px;
          vertical-align: baseline;
          margin-right: 4px;
          color: #FFF;
          top: -2px;
        }
      }
    }
  }
</style>
